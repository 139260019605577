import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Slider } from '@mui/material';
import { MinesContext } from '../../../../contexts/MinesContext';
import { colors } from '../../../../utils/colors';
import gem from '../../../../assets/images/ruby_egg.svg';
import bomb from '../../../../assets/images/coqbomb.svg';

const BombCount = ({ disabled=false }: { disabled?: boolean }) => {
  const { maxBombsCount, setMaxBombsCount, gridSize } = useContext(MinesContext);
  const [gemCount, setGemCount] = useState(gridSize - maxBombsCount);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setGemCount(newValue as number);
    setMaxBombsCount(gridSize - (newValue as number));
  };

  useEffect(() => {
    if (gemCount + maxBombsCount > gridSize) {
      const newValue = gridSize - gemCount
      setMaxBombsCount(newValue > 0 ? newValue : 1);
    }
    if (gemCount > gridSize) {
      setGemCount(gridSize);
    }
    if (maxBombsCount < gridSize - gemCount) {
      setMaxBombsCount(gridSize - gemCount);
    }
  }, [gridSize]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      width: '100%',
      marginY: '16px',
      padding: '8px',
      backgroundColor: colors.darkGrey,
      borderRadius: '8px',
      border: '1px solid ' + colors.borderGrey,
    }}>
      <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: 600, paddingX: '4px', marginBottom: '4px' }}>Gem / Bomb count</Typography>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Box sx={{
          paddingRight: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '64px',
          width: '100%'
        }}>
          <Box
            component="img"
            src={gem}
            sx={{ width: '28px', height: '28px', marginRight: '2px', padding: '4px' }}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff', fontSize: '16px' }}>
            {gemCount}
          </Typography>
        </Box>
        <Slider
          value={gemCount}
          onChange={handleSliderChange}
          aria-labelledby="bomb-count-slider"
          valueLabelDisplay="off"
          step={1}
          min={1}
          max={gridSize - 1}
          disabled={disabled}
          sx={{
            height: '4px',
            width: '95%',
            '& .MuiSlider-track': {
              background: '#00e701',
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              height: '16px',
              width: '16px',
              backgroundColor: '#fff',
              '&:before': {
                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.16)',
              },
              '&:active': {
                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.16)',
              },
              '&:hover': {
                boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.16)',
              },
            },
            '& .MuiSlider-rail': {
              background: '#f44336',
              opacity: 1,
            },
          }}
        />
        <Box sx={{
          paddingLeft: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '64px',
          width: '100%'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff', fontSize: '16px' }}>
            {maxBombsCount}
          </Typography>
          <Box
            component="img"
            src={bomb}
            sx={{ width: '28px', height: '28px', marginRight: '2px', padding: '4px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default BombCount;