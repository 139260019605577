import { useEffect } from 'react';
import Template from './Templates/UserPageTemplate';

export default function Profile() {
  useEffect(() => {
    window.location.href = '/transactions';
  })
  return (
    <Template>
      <div></div>
    </Template>
  )
};
