import { DateTime } from "luxon";

export const date = (time) => {
  const parsedDateTime = DateTime.fromISO(time, { setZone: false });
  return parsedDateTime.toFormat("LLLL d, h:mm a");
}

export const number = (digit = 2) => new Intl.NumberFormat('en-US', {
  minimumFractionDigits: digit,
  maximumFractionDigits: digit,
});
