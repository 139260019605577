import React, { createContext, useState, ReactNode } from 'react';

type McoqPriceContextType = {
  price: number;
  setPrice: (price: number) => void;
};

export const McoqPriceContext = createContext<McoqPriceContextType>({
  price: 0,
  setPrice: () => { },
});

export const McoqPriceProvider = ({ children }: { children: ReactNode }) => {
  const [price, setPrice] = useState<number>(0);

  return (
    <McoqPriceContext.Provider value={{ price, setPrice }}>
      {children}
    </McoqPriceContext.Provider>
  );
};
