import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { BASE_URL } from './config';

class HttpClient {
  private headers: Record<string, string> = {};

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
    });
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  setAuthHeader(token: string) {
    if (token) {
      this.headers.Authorization = `Bearer ${token}`;
    }
  }

  clearAuthHeader() {
    delete this.headers.Authorization;
  }

  private getConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    if (!config) {
      return {
        headers: this.headers,
      };
    }
    return {
      headers: config.headers
        ? {
          ...this.headers,
          ...config.headers,
        }
        : this.headers,
    };
  }

  async get(url: string, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.get(url, {
      ...config,
      headers: this.headers,
    });
    return response;
  }

  async post(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.post(
      url,
      data,
      this.getConfig(config),
    );
    return response;
  }

  async put(url: string, data?: any, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.put(
      url,
      data,
      this.getConfig(config),
    );
    return response;
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    const response = await this.axiosInstance.delete(
      url,
      this.getConfig(config),
    );
    return response;
  }
}

const httpClient = new HttpClient();;

export default httpClient;
