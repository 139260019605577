import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Toolbar, Typography } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import { DateTime } from "luxon";

import { BETTIMEOPTIONS } from "../../utils/config";
import { getIntervalDates } from "../../utils/timerange.util";
import { BetStreamProvider } from "../../contexts/BetStreamContext";
import { colors } from "../../utils/colors";

import Footer from "../Footer/Footer";
import Bets from "./Bets";
import SettlementFilter from "./Filters/SettlementFilter";
import FilterBetByTime from "./Filters/FilterBetByTime";
import InputFilter from "./Filters/InputFilter";
import StakeSizeFilter from "./Filters/StakeSizeFilter";
import SportFilter from "./Filters/SportFilter";
import { Option } from './components/FilterPopover';

const Betstream = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [selectedTime, setSelectedTimeState] = useState(searchParams.get('time') || BETTIMEOPTIONS[3]);
  const [selectedRange, setSelectRange] = useState({
    startDate: searchParams.get('startDate') ? DateTime.fromISO((searchParams.get('startDate') || '')).toJSDate() : DateTime.fromJSDate(DateTime.now().toJSDate()).startOf('day').toJSDate(),
    endDate: searchParams.get('endDate') ? DateTime.fromISO((searchParams.get('endDate') || '')).toJSDate() : DateTime.fromJSDate(DateTime.now().toJSDate()).endOf('day').toJSDate(),
    key: 'selection'
  });
  const [stringDateRange, setStringDateRange] = useState({
    startDate: searchParams.get('startDate') || "",
    endDate: searchParams.get('endDate') || ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [sportOptions, setSportOptions] = useState<Option[]>([{ label: 'All Sports', data: { name: '', id: '' } }]);

  const setSelectedTime = (option: string) => {
    setSelectedTimeState(option);
    searchParams.set('time', option);
    navigate({ search: searchParams.toString() });
  }

  const parseStakeSize = (key: string): number | null => {
    const stake = searchParams.get(key)
    if (stake !== null) {
      const stakeFloat = parseFloat(stake)
      return stakeFloat ? Number(stakeFloat.toFixed(2)) : null
    }
    return stake
  }

  const stakeSizeFilter = useMemo(() => ({
    minStake: parseStakeSize('minStake'),
    maxStake: parseStakeSize('maxStake')
  }), [searchParams.get('minStake'), searchParams.get('maxStake')]);

  const getSportId = () => {
    const sport = searchParams.get('sport')
    if (sport !== null) {
      const sportId = sportOptions.find((option) => option.data.name === sport)?.data.id
      return sportId
    }
    return ''
  }

  useEffect(() => {
    const sportId = getSportId();
  }, [location.search, sportOptions]);

  useEffect(() => {
    const stringDate = getIntervalDates(selectedTime, selectedRange);
    setStringDateRange(stringDate);

    if (selectedTime === BETTIMEOPTIONS[4]) {
      stringDate.startDate.length > 0 && searchParams.set('startDate', stringDate.startDate);
      stringDate.endDate.length > 0 && searchParams.set('endDate', stringDate.endDate);
    } else {
      searchParams.delete('startDate');
      searchParams.delete('endDate');
    }
    navigate({ search: searchParams.toString() });
  }, [selectedRange, selectedTime]);

  return (
    <BetStreamProvider>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%',
          backgroundColor: '#000',
          overflow: 'hidden',
          p: '16px',
          m: '0 auto',
          maxWidth: '100%',
        }}
      >
        <Toolbar variant="dense" />
        <Box sx={{
          flex: 1, overflow: 'auto', position: 'relative',
          '::-webkit-scrollbar': {
            width: '8px',
          },
          '::-webkit-scrollbar-track': {
            background: '#1c2327',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#ec5f59',
            borderRadius: '8px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#b24b45',
          },
        }}>
          <Box sx={{
            position: 'relative',
            top: '24px',
            left: '0px',
            paddingBottom: '8px',
            width: '100%',
          }}>
            <Typography variant="h4" color={"#fff"}>
              Bet Activity
            </Typography>
          </Box>
          <Box sx={{
            marginTop: '40px',
            borderRadius: '8px',
            paddingLeft: '0px',
            paddingBottom: '24px',
            backgroundColor: '#000',
            width: '100%',
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',
              overflowX: 'auto',
              '&::-webkit-scrollbar': { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}>
              <Box sx={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'center',
                gap: '8px',
                width: 'max-content', minWidth: 'max-content',
                overflowX: 'hidden',
                backgroundColor: 'rgb(41, 41, 41)',
                borderRadius: '8px',
                p: '4px 8px'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                  <TuneIcon sx={{ color: colors.pink }} />
                </Box>
                <SettlementFilter isLoading={isLoading} />
                <StakeSizeFilter isLoading={isLoading} />
                <SportFilter isLoading={isLoading} options={sportOptions} setOptions={setSportOptions} />
              </Box>
              <InputFilter />
              <FilterBetByTime
                selectedTime={selectedTime}
                selectedRange={selectedRange}
                handleRangeSelect={setSelectRange}
                handleTimeSelect={setSelectedTime}
                handleTimeFilterChange={() => { }}
                handleTimeRangeChange={e => {
                  setSelectRange({
                    startDate: e.startDate,
                    endDate: e.endDate,
                    key: 'selection'
                  });
                }}
              />
            </Box>
            <Bets
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              stringDateRange={stringDateRange}
              settlementFilter={searchParams.get('settlement') || ''}
              stakeSizeFilter={stakeSizeFilter}
              sportFilter={getSportId()}
            />
          </Box>

          <Footer />
        </Box>
      </Box>
    </BetStreamProvider>
  );
}

export default Betstream;
