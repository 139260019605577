import { useContext } from "react";
import { Button, Typography } from "@mui/material";

import { colors } from "../../../../utils/colors";
import { HiloContext } from "../../../../contexts/HiloContext";
import { higher, lower } from "../../../../api/hilo";
import { getProbabilityAndMultiplier } from "../../../../utils/getHiloProbabiltyMultiplier";

const getSvgBackgroundUrl = (isHigher: boolean) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 73.92 83.65">
      <path d="${isHigher
      ? 'M5.93,81.82a4.12,4.12,0,0,1-4.11-4.11V30.52a4.16,4.16,0,0,1,1.62-3.27L34.92,3.17a4.1,4.1,0,0,1,2.5-.85A4.16,4.16,0,0,1,40,3.2L70.53,27.27a4.1,4.1,0,0,1,1.56,3.23V77.71A4.11,4.11,0,0,1,68,81.82Z'
      : 'M36.5,81.57A4.13,4.13,0,0,1,34,80.69L3.39,56.63A4.1,4.1,0,0,1,1.82,53.4V6.18A4.12,4.12,0,0,1,5.93,2.07H68a4.12,4.12,0,0,1,4.11,4.11v47.2a4.12,4.12,0,0,1-1.61,3.26L39,80.73A4.15,4.15,0,0,1,36.5,81.57Z'}"
      fill="${isHigher ? colors.lightGreen : colors.pink}" stroke="${colors.grey800}" stroke-width="4"/>
    </svg>
  `;
  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}")`;
};

const SvgButton = ({ isHigher, children }: { isHigher?: boolean; children: React.ReactNode }) => {
  const svgBackgroundUrl = getSvgBackgroundUrl(isHigher || false);
  const { isLoading, setNewCard, gameActive, setGameActive, swipeCard } = useContext(HiloContext);

  const onClick = async () => {
    swipeCard();
    const game = await (isHigher ? higher() : lower());
    const card = game.state.rounds.slice(-1)[0].card;
    // @ts-ignore
    setNewCard(false, card.rank, card.suit, game.state.rounds.slice(-1)[0].guess, game.state.rounds[game.state.rounds.length - 1].payoutMultiplier);
    if (game.active !== gameActive) setGameActive(game.active)
  }

  return (
    <Button
      disabled={isLoading || !gameActive}
      onClick={onClick}
      sx={{
        width: "120px",
        height: "120px",
        backgroundImage: svgBackgroundUrl,
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#000",
        border: "none",
        padding: "8px",
        margin: "8px",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "transparent",
          transform: "scale(0.95)"
        },
        "&:focus": {
          backgroundColor: "transparent",
        },
      }}
      disableRipple
    >
      {children}
    </Button>
  );
};

export const HigherButton = () => {
  const { currentCard } = useContext(HiloContext)
  const { probability } = getProbabilityAndMultiplier(currentCard.rank, "higher")
  let text = currentCard.rank === "K" ? "Equal" : "Higher or equal"
  text = currentCard.rank === "A" ? "Higher" : text

  return (
    <SvgButton isHigher>
      <Typography sx={{ fontWeight: 700, fontSize: "inherit", pt: '26px',  color: colors.darkGrey }}>{text} <br/>{(probability * 100).toFixed(2)}%</Typography>
    </SvgButton>
  );
}

export const LowerButton = () => {
  const { currentCard } = useContext(HiloContext)
  const { probability } = getProbabilityAndMultiplier(currentCard.rank, "lower")
  let text = currentCard.rank === "A" ? "Equal" : "Lower or equal"
  text = currentCard.rank === "K" ? "Lower" : text

  return (
    <SvgButton>
      <Typography sx={{ fontWeight: 700, fontSize: "inherit", pb: '16px', color: colors.freshWhite }}>{text} <br/>{(probability * 100).toFixed(2)}%</Typography>
    </SvgButton>
  );
}
