import { Box } from '@mui/material';

import PageWrapper from '../../../wrappers/PageWrapper';
import GameField from './GameField/GameField';
import BetMenu from './BetMenu/BetMenu';

import { KenoProvider } from '../../../contexts/KenoContext';
import { gameContainerStyles } from '../utils';

const KenoGame = () => {
  return (
    <KenoProvider>
      <PageWrapper>
        <Box sx={gameContainerStyles}>
          <BetMenu />
          <GameField />
        </Box>
      </PageWrapper>
    </KenoProvider>
  )
}

export default KenoGame;