import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import { Toolbar } from '@mui/material';
import Navigation from '../Navigation';
import { UserProvider, UserDetailsContext } from '../../../contexts/UserContext';
import Footer from '../../Footer/Footer';

const Template = ({ children }: { children: React.ReactNode }) => {
  const { address } = useContext(UserDetailsContext);
  useEffect(() => {
    if (!address && !localStorage.getItem('jwtToken')) {
      window.location.href = '/';
    }
  }, [address]);

  return (
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#000',
        overflow: 'hidden',
        p: '16px',
        m: '0 auto',
        maxWidth: '1300px',
      }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ flex: 1, overflow: 'auto', position: 'relative' }}>
        <Box sx={{
          position: 'relative',
          top: '24px',
          left: '0px',
          paddingBottom: '8px',
        }}>
          <Navigation />
        </Box>
        <Box sx={{ marginTop: '42px', paddingLeft: '0px' }}>
          {children}
        </Box>
      </Box>
      <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
        <Footer />
      </Box>
    </Box>
  );
};

export default Template;
