import React, { useContext } from "react"
import { Typography } from '@mui/material';

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import BetButton from "../../common/BetButton";
import GameButtons from "./GameButtons";

import { colors } from "../../../../utils/colors";
import { HiloContext } from "../../../../contexts/HiloContext";
import { startGame, cashout } from "../../../../api/hilo";
import { showToast, TOASTTYPE } from "../../../../utils/toast";

const BetMenu = ({ isTablet }: { isTablet: boolean }) => {
  const {
    betAmount,
    currentCard,
    setBetAmount,
    isLoading, setIsLoading,
    gameActive, setGameActive,
    deck, resetDeck
  } = useContext(HiloContext);

  const playGame = () => {
    deck.length > 1 && resetDeck()
    setIsLoading(true)
    startGame(currentCard)
      .then(game => setGameActive(true))
      .catch(err => showToast(TOASTTYPE.error, err))
      .finally(() => setIsLoading(false))
  }

  const cashoutGame = () => {
    setIsLoading(true)
    cashout()
      .then(game => setGameActive(false))
      .catch(err => showToast(TOASTTYPE.error, err))
      .finally(() => setIsLoading(false))
  }

  return (
    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} disabled={isLoading || gameActive} />
      {!isTablet && <GameButtons />}
      <BetButton
        disabled={(gameActive && (deck[deck.length - 1].payoutMultiplier || 0) < 1.001) || isLoading}
        onClick={gameActive ? cashoutGame : playGame}
        sx={{
          marginTop: isTablet ? '16px' : 0,
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite,
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>{gameActive ? 'Cashout' : 'Bet'}</Typography>
        {isLoading && <Loader />}
      </BetButton>
    </BetMenuWrapper>
  )
}

export default BetMenu