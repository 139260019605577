import { Home, Sports, SportsBaseball, SportsBasketball, SportsFootball, SportsMartialArts, SportsSoccer, SportsTennis } from "@mui/icons-material";


const SportIcon = (props) => {
    const { name = "" } = props;

    const normalisedName = "" || name.toLowerCase();

    switch (normalisedName) {
        case "home":
            return (<Home {...props} />);
        case 'soccer':
            return <SportsSoccer {...props} />;
        case 'football':
            return <SportsFootball {...props} />;
        case 'basketball':
            return <SportsBasketball {...props} />;
        case 'mma':
            return <SportsMartialArts {...props} />;
        case 'tennis':
            return <SportsTennis {...props} />;
        case 'baseball':
            return <SportsBaseball {...props} />;
        default:
            return <Sports {...props} />;
    }
}

export default SportIcon;
