import React, { useContext, useState } from "react"
import { Box, Typography, useMediaQuery, useTheme, Button, styled } from '@mui/material';

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import BetButton from "../../common/BetButton";

import { KenoContext } from '../../../../contexts/KenoContext';
import { colors } from "../../../../utils/colors";

const generateUniqueRandomNumbers = (count: number, min: number, max: number): number[] => {
  const numbers = new Set<number>();
  while (numbers.size < count) {
    numbers.add(Math.floor(Math.random() * (max - min + 1)) + min);
  }
  return Array.from(numbers);
};

const TableButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: colors.grey800,
  '&:hover': {
    backgroundColor: colors.freshGrey
  },
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  color: colors.textWhite,
}))

const BetMenu = () => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  const { selectedCells, setWinningCells, setSelectedCells } = useContext(KenoContext);
  const [betAmount, setBetAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const playGame = () => {
    setIsLoading(true)
    const numbersToAdd = generateUniqueRandomNumbers(10, 1, 40);
    console.log(numbersToAdd)
    setWinningCells([]);

    numbersToAdd.forEach((number, i) => {
      setTimeout(() => {
        setWinningCells(prev => {
          if (prev.length === 9) {
            setTimeout(() => setIsLoading(false), 200);
          }
          return [...prev, number];
        });
      }, i * 150);
    })
  };

  const autoPick = () => {
    setIsLoading(true)
    setWinningCells([]);
    setSelectedCells([]);
    generateUniqueRandomNumbers(10, 1, 40).forEach((number, i) => {
      setTimeout(() => {
        setSelectedCells(prev => {
          if (prev.length === 9) {
            setTimeout(() => setIsLoading(false), 200);
          }
          return [...prev, number];
        });
      }, i * 150);
    })
  }

  const clearTable = () => {
    setSelectedCells([]);
    setWinningCells([]);
  }

  return (

    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} />
      <Box sx={{ marginY: '24px', display: 'grid', gap: '8px', gridTemplateColumns: 'repeat(2,minmax(0,1fr))' }}>
        <TableButton onClick={autoPick} disabled={isLoading}>
          <Typography variant="body2" sx={{ flexGrow: 1, fontWeight: 700, padding: '3px 6px' }}>Auto pick</Typography>
        </TableButton>
        <TableButton onClick={clearTable} disabled={isLoading}>
          <Typography variant="body2" sx={{ flexGrow: 1, fontWeight: 700, padding: '3px 6px' }}>Clear table</Typography>
        </TableButton>
      </Box>
      <BetButton
        disabled={selectedCells.length === 0 || isLoading}
        onClick={playGame}
        sx={{
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>Bet</Typography>
        {isLoading && <Loader />}
      </BetButton>
    </BetMenuWrapper>
  )
}

export default BetMenu