import { createContext, useState, useCallback, useEffect, useRef } from 'react';
import { useDisconnect } from 'wagmi';
import httpClient from '../utils/http-client';
import { TOASTTYPE, showToast, SESSION_EXPIRED, LOGINERROR } from '../utils/toast';
import { formatEther } from 'viem';
import {
  useAccount,
  useSignMessage,
  useConnect,
  useAccountEffect,
} from 'wagmi';
import { wagmiStorage } from '../utils/storages';
import { avalanche } from 'wagmi/chains';
import { jwtDecode } from "jwt-decode";

type UserContextType = {
  address: string;
  setAddress: (address: string) => void;
  username: string;
  setUsername: (username: string) => void;
  balance: string;
  setBalance: (balance: string | ((prevBalance: string) => string)) => void;
  setFormattedBalance: (balance: any) => void;
  userJWT: string;
  setUserJWT: (jwt: string) => void;
  logout: () => void;
  handleConnect: () => Promise<void>;
  handleLogin: (address: string, signature: string) => Promise<void>;
  handleTransaction: () => Promise<void>;
};

interface UserResponseData {
  address: string;
  balance: any;
  createdAt: string;
  referralCode: string;
  updatedAt: string;
  username: string | null;
}

export const UserDetailsContext = createContext<UserContextType>({
  address: '',
  username: '',
  balance: '',
  userJWT: '',
  setBalance: () => {},
  setFormattedBalance: () => {},
  setAddress: () => {},
  setUsername: () => {},
  setUserJWT: () => {},
  logout: () => {},
  handleConnect: async () => {},
  handleLogin: async () => {},
  handleTransaction: async () => {},
});

export function UserProvider({ children }: any) {
  const [address, setAddress] = useState('');
  const [username, setUsername] = useState('');
  const [balance, setBalance] = useState('?? MCOQ');
  const [userJWT, setUserJWT] = useState('');
  const isFetchingBalance = useRef(false);

  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const { connect, connectors } = useConnect();
  const account = useAccount();

  const setFormattedBalance = useCallback((balance: any) => {
    const formattedBalance = Math.floor(Number(formatEther(balance)) * 100) / 100;
    setBalance(`${formattedBalance.toFixed(2)} MCOQ`);
  }, [setBalance]);

  const logout = useCallback(() => {
    setAddress('');
    setUsername('');
    setBalance('?? MCOQ');
    setUserJWT('');
    localStorage.removeItem('jwtToken');
    httpClient.clearAuthHeader();
    disconnect();
  }, [disconnect]);

  // check jwt health
  const isTokenExpired = (token: string) => {
    try {
      const decoded: any = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
      return false;
    } catch (error) {
      return true; 
    }
  };

  const fetchMe = useCallback(async (userJWT: string): Promise<UserResponseData> => {
    const response = await httpClient.get('user/me', {
      headers: {
        Authorization: `Bearer ${userJWT}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to get user data. Status code: ' + response.status);
  }, []);

  const handleLogin = useCallback(
    async (address: string, signature: string) => {
      try {
        const payload = {
          signedMessage: signature,
          address: address,
          referralCode: null,
        };

        const res = await httpClient.post('/user/login', payload);

        if (res.status === 201) {
          const token = res.data.accessToken;
          setUserJWT(token);
          localStorage.setItem('jwtToken', token);
          httpClient.setAuthHeader(token);
          const userData = await fetchMe(token);
          setFormattedBalance(userData.balance);
          if (account.connector) {
            wagmiStorage.setItem('recentConnectorId', account.connector.id);
          }
        }
      } catch (e) {
        showToast(TOASTTYPE.error, LOGINERROR);
      }
    },
    [account.connector, fetchMe]
  );

  const handleConnect = useCallback(async () => {
    const address = account.address || '0x';
    setAddress(address);
    try {
      const { data } = await httpClient.get(`/user/login-nonce/${address}`);
      const signature = await signMessageAsync({ account: address, message: data });
      await handleLogin(address, signature);
    } catch {
    }
  }, [account.address, handleLogin, signMessageAsync]);

  // autimatic login
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (token && !isTokenExpired(token)) {
      httpClient.setAuthHeader(token);
      fetchMe(token)
        .then((data: UserResponseData) => {
          setUserJWT(token);
          setFormattedBalance(data.balance);
          setAddress(data.address || '0x');
        })
        .catch((error) => {
          console.error(error);
          logout();
        });
    } else if (token) {
      logout();
    }
  }, [fetchMe, logout]);

  // trigger wallet connection if session is live but wallet is not connected
  const handleTransaction = useCallback(async () => {
    if (!account.isConnected) {
      const recentConnectorId = wagmiStorage.getItem('recentConnectorId');

      const tryConnect = async (connector: any) => {
        try {
          await connect({ chainId: avalanche.id, connector });
          return true;
        } catch {
          return false;
        }
      };

      if (recentConnectorId) {
        const recentConnector = connectors.find(
          (connector) => connector.id === recentConnectorId
        );
        if (recentConnector) {
          const isConnected = await tryConnect(recentConnector);
          if (isConnected) return;
        }
      }

      for (const connector of connectors) {
        if (recentConnectorId && connector.id === recentConnectorId) continue;
        const isConnected = await tryConnect(connector);
        if (isConnected) break;
      }
    }
  }, [account.isConnected, connectors, connect]);

  // listen for token removal
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'jwtToken' && !event.newValue) {
        logout(); // logout on token removal
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [logout]);

  // polling balance
  useEffect(() => {
    const fetchBalance = async () => {
      if (userJWT && isTokenExpired(userJWT)) {
        clearInterval(interval);
        showToast(TOASTTYPE.error, SESSION_EXPIRED);
        logout();
        return;
      }
      if (isFetchingBalance.current) return; // prevent fetching multiple times
      isFetchingBalance.current = true;

      try {
        if (userJWT) {
          const response = await httpClient.get('user/me');
          setFormattedBalance(response.data.balance);
        }
      } catch (error: any) {
        if (error.response?.status === 401) {
          clearInterval(interval);
          showToast(TOASTTYPE.error, error.response.data.message);
          logout();
        }
      } finally {
        isFetchingBalance.current = false;
      }
    };

    const interval = setInterval(fetchBalance, 2500);
    return () => clearInterval(interval);
  }, [userJWT, logout]);

  // reset wagmi connections & button state
  useAccountEffect({
    onDisconnect() {
      window.location.reload();
    }
  })

  const value = {
    address,
    username,
    balance,
    userJWT,
    setUsername,
    setAddress,
    setBalance,
    setUserJWT,
    logout,
    handleConnect,
    handleTransaction,
    handleLogin,
    setFormattedBalance
  };

  return (
    <UserDetailsContext.Provider value={value}>
      {children}
    </UserDetailsContext.Provider>
  );
}
