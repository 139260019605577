import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';

import { Dropdown, Option } from '../components/FilterPopover';

import { GET_SPORTS } from '../../../graphql/queries';

type SportFilterProps = {
  isLoading: boolean
  options: Option[]
  setOptions: React.Dispatch<React.SetStateAction<Option[]>>
}
export const SportFilter = ({ isLoading, options, setOptions }: SportFilterProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const { data } = useQuery(GET_SPORTS);

  useEffect(() => {
    if (data && data.sports && options.length <= 1) {
      setOptions(prev => [...prev, ...data.sports.map((sport) => ({ label: sport.name, data: {name: sport.name.toLowerCase(), id: sport.id} }))]);
    }
  }, [data]);

  const setSportFilter = (optionData: Option['data']) => {
    optionData.name !== options[0].data.name ? searchParams.set('sport', optionData.name) : searchParams.delete('sport');
    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  }

  const getPreviewText = () => {
    const option = options.find((option) => option.data.name === searchParams.get('sport'))
    return option?.label || 'All Sports';
  }

  return (
    <Dropdown 
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      previewText={getPreviewText()}
      title='Sport'
      isLoading={isLoading}
      mainOptions={options}
      selectOptionCallback={setSportFilter}
    />
  )
} 

export default SportFilter