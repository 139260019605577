import { useContext, useMemo, useState } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";

import PercentIcon from '@mui/icons-material/Percent';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import { DiceContext } from "../../../../contexts/DiceContext";
import { textFieldStyles } from "../../utils";
import { getDiceMultiplier } from "../../../../utils/utils";
import { DICE_HOUSE_EDGE } from "../../../../utils/config";
import { colors } from "../../../../utils/colors";

const Footer = () => {
  const {
    target, winChance,
    condition, toggleCondition,
    currentMultiplier,
    setTargetAndWinChance, setMultiplier
  } = useContext(DiceContext)

  const maxWinChance = DICE_HOUSE_EDGE*100
  const minWinChance = 2

  const minMultiplier = useMemo(() => {
    return getDiceMultiplier({ winChance: DICE_HOUSE_EDGE*100, houseEdge: DICE_HOUSE_EDGE })
  }, [])
  const maxMultiplier = useMemo(() => {
    return getDiceMultiplier({ winChance: 2, houseEdge: DICE_HOUSE_EDGE })
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setTargetAndWinChance(undefined, value);
    }
  }

  const handleBlur = () => {
    if (target > maxWinChance) {
      setTargetAndWinChance(maxWinChance);
    } else if (target < minWinChance) {
      setTargetAndWinChance(minWinChance);
    }

    setTargetAndWinChance(undefined, Number(winChance.toFixed(2)));
  }

  const handleMultiplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      setMultiplier(value);
    }
  }

  const handleInputError = (label: string, value: number): string => {
    if (label === 'Win Chance') {
      if (value < minWinChance || value > maxWinChance) {
        return `Value out of range (${minWinChance} - ${maxWinChance})`;
      }
      return "";
    }

    if (label === condition) {
      let min = condition === 'above' ? minWinChance : 2
      let max = condition === 'below' ? maxWinChance : 100 - minWinChance
      if (value < min || value > max) {
        return `Value out of range (${min} - ${max})`;
      }
      return "";
    }

    if (label === 'Multiplier') {
      if (value < 1 || value > maxMultiplier) {
        return `Value out of range (${minMultiplier.toFixed(2)} - ${maxMultiplier.toFixed(2)})`;
      }
      return "";
    }
    return "";
  }

  const renderTextField = (
    { label, value, adornmentIcon, readOnly = false, minMax, onChange, onClick, onBlur }:
      {
        label: string,
        value: number | string,
        adornmentIcon: JSX.Element,
        readOnly: boolean,
        minMax?: { min: number, max: number },
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
        onClick?: () => void,
        onBlur?: () => void,
      }
  ) => (
    <Box sx={{ width: '100%', textAlign: 'left' }} >
      <Typography sx={{ color: '#fff' }}>{label.charAt(0).toUpperCase() + label.slice(1)}</Typography>
      <TextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        type="number"
        error={handleInputError(label, Number(value)) ? true : false}
        helperText={handleInputError(label, Number(value))}
        inputProps={{
          min: minMax? minMax.min : 1,
          max: minMax? minMax.max : 98,
          step: 0.01,
          inputMode: 'decimal',
          pattern: '^[0-9]*[.,]?[0-9]*$',
          readOnly: readOnly,
        }}
        sx={{
          ...textFieldStyles,
          '& .MuiOutlinedInput-root': {
            ...textFieldStyles['& .MuiOutlinedInput-root'],
            '& .MuiInputBase-input': {
              ...textFieldStyles['& .MuiOutlinedInput-root']['& .MuiInputBase-input'],
              cursor: onClick ? 'pointer' : 'auto',
            },
          },
        }}
        InputProps={{
          style: { cursor: onClick ? 'pointer' : 'auto' },
          endAdornment: (
            <InputAdornment position="end" sx={{ color: '#fff' }} onClick={onClick}>
              {adornmentIcon}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { lg: 'row', xs: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        p: { xs: '0px', lg: '16px' },
        width: '100%',
        columnCount: 3,
      }}
    >
      {renderTextField({
        label: 'Multiplier',
        value: currentMultiplier,
        readOnly: false,
        adornmentIcon: <CloseIcon />,
        onChange: handleMultiplierChange,
        minMax: { min: 0, max: maxMultiplier },
      })}
      {renderTextField({
        label: condition,
        value: target.toFixed(2),
        readOnly: true,
        adornmentIcon: <RefreshIcon sx={{bgcolor: colors.pink, p: '2px', borderRadius: '4px', fontSize: '34px', '&:hover': { bgcolor: colors.transparentPink }}}/>,
        onChange: () => { },
        onClick: toggleCondition
      })}
      {renderTextField({
        label: 'Win Chance',
        value: winChance,
        readOnly: false,
        adornmentIcon: <PercentIcon />,
        onChange: handleChange,
        onBlur: handleBlur,
        minMax: { min: 0, max: maxWinChance },
      })}
    </Box>
  );
}

export default Footer;
