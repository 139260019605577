export const BASE_URL = process.env.REACT_APP_ENV === 'production' ?
  'https://api.coqbook.com/'
  : process.env.REACT_APP_ENV === 'local'? 'http://localhost:3000' : 'https://api.gongjishu.com';
export const SPORTSBOOK_URL = process.env.REACT_APP_ENV === 'production' ?
  'https://d23uppapk1ps6p.cloudfront.net/'
  : 'https://d36ga3mn2uzt26.cloudfront.net/';
export const PARTNER_ID = process.env.REACT_APP_ENV === 'production' ? "COQBOOK" : "GONGJISHU";
export const HOUSE_CONTRACT_ADDRESS = '0xD0Ce7961Ec9500f5DAa734061f466613882A56DF';
export const TRADER_JOE_ROUTER = '0x60aE616a2155Ee3d9A68541Ba4544862310933d4'

export const COQ_CONTRACT_ADDRESS = '0x420fca0121dc28039145009570975747295f2329';
export const WAVAX_CONTRACT_ADDRESS = '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7';
export const USDC_CONTRACT_ADDRESS = '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e';

export const MCOQ_PRICE = 1_000_000

export const AVAX_EXPLORER_URL = 'https://snowtrace.io/'

export const SOCIAL_LINKS = {
  twitter: 'https://x.com/coqbook',
  discord: 'https://discord.com/invite/6fGKxNgFaq'
}

export const BETMODE = {
  single: 'single',
  combo: 'combo',
  system: 'system',
};
export const TOASTTYPE = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning'
};
export const BETFILTEROPTIONS = ["All", "Open", "Won", "Lost", "Refund"];
export const BETTIMEOPTIONS = ["Today", "Last Week", "Last Month", "Latest Bets", "Custom"];
export const CREATEBETMESSAGES = {
  success: "Bet Created! You can view your bets in 'My bets'",
  fail: "Unable to create bet, please try again later",
  invalidOutcome: "Please select outcomes!",
  invalidStake: 'Bet amount must be greater than 0.',
  invalidBetType: "Please select single as bet type.",
  invalidOutcomeCount: "Please select only one outcome for each market."
}
export const NOBETSMESSAGES = {
  "All": "No bets are available",
  "Open": "You have no open bets",
  "Won": "You have no won bets",
  "Lost": "You have no lost bets",
  "Refund": "You have no refunded bets",
}
export const BETSETTLEMENT = {
  won: {
    text: "total won", settlementColor: "#00FF00"
  },
  win: {
    text: "won", settlementColor: "#00FF00"
  },
  half_win: {
    text: "half win", settlementColor: "#00FF00"
  },
  half_lose: {
    text: "half lose", settlementColor: "red"
  },
  lost: {
    text: "", settlementColor: "red"
  },
  lose: {
    text: "lost", settlementColor: "red"
  },
  open: {
    text: "potential win", settlementColor: "yellow"
  },
  pending: {
    text: "open", settlementColor: "yellow"
  },
  refund: {
    text: "refund", settlementColor: "yellow"
  },
  cancelled: {
    text: "cancelled", settlementColor: "yellow"
  }
}

export const ODDFORMATS = ['european odds', 'american odds'];

export const HILO_HOUSE_EDGE = 0.99
export const DICE_HOUSE_EDGE = 0.98;
