import { useContext } from "react";
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { HiloContext, Card as CardProps } from "../../../../contexts/HiloContext";
import { skipCard } from "../../../../api/hilo";
import { colors } from "../../../../utils/colors";

const SkipCardButton = ({ positionCoords }: { positionCoords: { right?: number, left?: number } }) => {
  const {
    isSwiping,
    gameActive, setNewCard,
    swipeCard
  } = useContext(HiloContext);

  const onClick = async () => {
    swipeCard()
    if (!gameActive) {
      setNewCard(true)
      return
    }
    const game = await skipCard()

    const rank = game.state.rounds.slice(-1)[0].card.rank
    const suit = game.state.rounds.slice(-1)[0].card.suit
    setNewCard(false, rank as CardProps['rank'], suit as CardProps['suit'], "skip")
  }

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        position: 'absolute',
        ...positionCoords,
        top: -20,
        minWidth: '32px',
        width: '44px',
        p: '8px',
        zIndex: 10,
        opacity: isSwiping ? 0.2 : 1,
        transition: 'opacity 0.1s ease',
        backgroundColor: colors.pink,
        '&:hover': {
          backgroundColor: colors.transparentPink
        },
      }}
    >
      <KeyboardDoubleArrowRightIcon />
    </Button>
  )
}

export default SkipCardButton