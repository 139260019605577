import { Box, Typography } from "@mui/material";
import { colors } from "../../../utils/colors";
import coqhead_white from '../../../assets/images/coqhead_white.svg';

const styles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#000',
  borderRadius: '4px',
  userSelect: 'none',
  position: 'absolute',
  top: 0,
  overflow: 'hidden',
  boxShadow: '0 .25em 0.25em #0710174d',
  backfaceVisibility: 'hidden',
};

export type CardProps = {
  rank: '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'J' | 'Q' | 'K' | 'A' | '';
  suit: '♠' | '♡' | '♢' | '♣' | '';
  color: 'red' | 'black';
  id: string;
  side: 'front' | 'back';
}

export const GameCardBack = ({ cardSx, transform }: { cardSx: { width: any, height: any }, transform?: boolean }) => (
  <Box
    sx={{
      width: cardSx.width,
      height: cardSx.height,
      ...styles,
      backgroundColor: colors.pink,
      transform: transform ? 'rotateY(180deg)' : 'none',
    }}
  >
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <Box
        component="img"
        alt="logo"
        sx={{
          height: '75%',
          objectFit: "cover",
          objectPosition: "center",
          pl: '4px',
          content: {
            xs: `url(${coqhead_white})`,
            sm: `url(${coqhead_white})`,
          },
        }}
      />
    </Box>
  </Box>
);


export const GameCard = ({ cardSx, card, animation, outline, transform, outlineDelayMs = 500 }:
  { cardSx: { width: any, height: any, fontSize: any }, 
  card: CardProps, animation?: string, 
  outline?: string, 
  transform?: boolean, 
  outlineDelayMs?: number }) => {

  return (
    <Box sx={{
      position: 'relative',
      width: cardSx.width,
      height: cardSx.height,
    }}>
      <Box
        sx={{
          width: cardSx.width,
          height: cardSx.height,
          position: 'relative',
          transformStyle: 'preserve-3d',
          transformOrigin: 'center',
          transition: 'transform 0.3s',
          transform: transform ? `
            rotateY(${card.side === 'front' ? 0 : 180}deg)
          ` : 'none',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            width: cardSx.width,
            height: cardSx.height,
            ...styles,
            backgroundColor: '#fff',
            animation: animation || 'none',
            transition: outline ? 'outline 0.15s ease-in-out ' + outlineDelayMs + 'ms' : 'none',
            ...(outline ? { outline: outline } : {}),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: '10%',
              width: 'max-content',
              color: card.color,
              fontSize: cardSx.fontSize,
            }}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 'inherit' }}>
              {card.rank}
            </Typography>
            {/* TODO: change to svg assets & add function to get suit */}
            {card.suit}
          </Box>
        </Box>
        <GameCardBack cardSx={cardSx} transform={true} />
      </Box>
    </Box>
  );
};

export default GameCard;
