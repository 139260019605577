import { useRef, useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";

import { GameCardBack } from "../../common/GameCard";
import Hand from "./Hand";
import { CardProps } from "../../common/GameCard";

import { BlackjackContext, Card as BlackjackCardProps } from "../../../../contexts/BlackjackContext";
import { gameFieldStyles } from "../../utils";

import blackjack_pays from '../../../../assets/images/blackjack_pays.svg'

// temporary function there are no assets yet
const convertSuits = (cards: BlackjackCardProps[]) => {
  return cards.map((card) => {
    return {
      ...card,
      suit: (card.suit === 'S' ? '♠' : card.suit === 'H' ? '♥' : card.suit === 'D' ? '♦' : '♣') as CardProps['suit']
    } as CardProps
  })
}

export const calculateHandValue = (cards: BlackjackCardProps[]): number => {
  let value = 0;
  let aceCount = 0;

  for (const card of cards) {
    if (card.rank === 'A') {
      value += 11;
      aceCount++;
    } else if (card.rank === 'K' || card.rank === 'Q' || card.rank === 'J') {
      value += 10; 
    } else {
      value += parseInt(card.rank);
    }
  }

  while (value > 21 && aceCount > 0) {
    value -= 10;
    aceCount--;
  }

  return value;
}

const Deck = () => (
  <Box sx={{ position: 'relative', fontSize: '1.2em' }}>
    {[...new Array(5)].map((_, index) => (
      <Box key={index} sx={{
        position: 'absolute', zIndex: 0, transform: `translate(0, ${10 - index * 2}%)`, width: '5em',
        height: '7.9em',
      }}>
        <GameCardBack
          cardSx={{ width: 'inherit', height: '100%' }}
        />
      </Box>
    ))}
  </Box>
)

const GameField = ({ isTablet }: { isTablet: boolean }) => {
  const { dealerHand, playerHand, scores, gameActive, currentHandNumber } = useContext(BlackjackContext);
  const [fontSize, setFontSize] = useState(1);
  const deckRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const maxWidth = 868;
        const containerRect = containerRef.current.getBoundingClientRect();
        if (containerRect.width >= maxWidth) {
          setFontSize(1);
        } else if (containerRect.width <= maxWidth / 2) {
          setFontSize(0.5);
        } else {
          setFontSize(containerRect.width / maxWidth);
        }
      }
    };

    updateFontSize();

    window.addEventListener("resize", updateFontSize);
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  const getHandStatus = (isPlayer: boolean, scorePlayer: number, scoreDealer: number) => {
    if (gameActive) return ''
    if (!isPlayer && scores.player.length > 1) return ''
    if (scorePlayer > 21) return isPlayer ? 'lose' : 'win';
    if (scoreDealer > 21) return isPlayer ? 'win' : 'lose';
    if (scoreDealer === scorePlayer && scoreDealer === 21 && 
      dealerHand.length === 2 && (playerHand.length > 1 || playerHand[0].length > 2)) return isPlayer ? 'lose' : 'win';
    if (scorePlayer === scoreDealer && dealerHand.length > 1) return 'draw';
    if (scorePlayer > scoreDealer) return isPlayer ? 'win' : 'lose';
    if (scoreDealer > scorePlayer) return isPlayer ? 'lose' : 'win';
  }


  return (
    <Box sx={{ flexGrow: { xl: 1 }, width: '100%', height: '100%' }}>
      <Box ref={containerRef} sx={{ ...gameFieldStyles, height: '100%' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: isTablet ? '16px 24px' : '48px',
          borderRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
          fontSize: `${fontSize}em`,
          height: '100%',
        }}>
          <Box ref={deckRef} sx={{ fontSize: '1.2em', width: '5em', height: '7.9em', position: 'absolute', top: 0, right: 0, zIndex: 0, transform: 'translate(-50%, -50%)' }}>
            <Deck />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
            pt: '32px',
            backgroundImage: `url(${blackjack_pays})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '40%',
          }}>
            {/* dealer hand */}
            <Hand
              hand={convertSuits(dealerHand)}
              deckRef={deckRef}
              score={dealerHand.filter(card => card.side === 'front').length < 2 ? scores.dealer[0] : calculateHandValue(dealerHand)}
              status={getHandStatus(false, scores.player[0], scores.dealer[0])}
              outlineDelayMs={0}
            />
            <Box sx={{ width: '100%', height: '0', py: { xs: '32px', md: '64px' } }}></Box>
            {/* player hand/hands(if split) */}
            <Box sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: playerHand.length > 0 ? 'space-between' : 'center'
            }}>
              {playerHand.map((hand, index) => (
                <Hand
                  key={`player-hand-${index}`}
                  hand={convertSuits(hand)}
                  deckRef={deckRef}
                  score={calculateHandValue(hand)}
                  status={scores.player.length > 1 && currentHandNumber === index && gameActive ? 'pick' : getHandStatus(true, scores.player[index], scores.dealer[0])}
                  outlineDelayMs={0}
                />
              ))
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GameField;