import { ODDFORMATS } from "./config";
import { number } from "./format.util";

export const conversionOdd = (odd, format) => {
  if (format === ODDFORMATS[1]) {
    if (odd >= 2) {
      return `+${number(0).format((odd - 1) * 100)}`;
    }
    return number(0).format(-100 / (odd - 1));
  }
  return odd;
}
