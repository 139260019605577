import { Box } from "@mui/material";

import PageWrapper from "../../wrappers/PageWrapper";
import Stats from "./Stats";

const StatsPage = () => {
  return (
    <PageWrapper title={'Statistics'}>
      <Box sx={{ marginTop: '40px', borderRadius: '8px', paddingLeft: '0px', paddingBottom: '24px', backgroundColor: '#000' }}>
        <Stats />
      </Box>
    </PageWrapper>
  );
}

export default StatsPage
