import React, { useState, createContext, ReactNode, useEffect, useCallback } from 'react';
import { activeGame } from '../api/hilo';

export type Card = {
  rank: '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'J' | 'Q' | 'K' | 'A' | '';
  suit: '♠' | '♡' | '♢' | '♣' | '';
  color: 'red' | 'black';
  id: string;
  side: 'front' | 'back';
  swiped?: boolean;
};

export type DeckCard = Card & {
  choice?: 'higher' | 'lower' | 'skip'
  payoutMultiplier?: number
}

interface HiloContextProps {
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  currentCard: Card;
  setCurrentCard: React.Dispatch<React.SetStateAction<Card>>;
  generateCard: (initial: boolean) => Card;
  isSwiping: boolean;
  setIsSwiping: React.Dispatch<React.SetStateAction<boolean>>;
  swipeCard: () => void;
  deck: DeckCard[];
  gameActive: boolean;
  setGameActive: React.Dispatch<React.SetStateAction<boolean>>;
  setNewCard: (initial?: boolean, rank?: Card['rank'], suit?: Card['suit'], choice?: DeckCard['choice'], multiplier?: DeckCard['payoutMultiplier']) => void
  resetDeck: () => void
}

export const HiloContext = createContext<HiloContextProps>({
  betAmount: 0,
  setBetAmount: () => { },
  isLoading: false,
  setIsLoading: () => { },
  currentCard: { rank: 'A', suit: '♠', color: 'black', id: 'initial-card', side: 'back' },
  setCurrentCard: () => { },
  generateCard: (initial: boolean) => ({ rank: 'A', suit: '♠', color: 'black', id: 'initial-card', side: 'back' }),
  isSwiping: false,
  setIsSwiping: () => { },
  swipeCard: () => { },
  deck: [],
  gameActive: false,
  setGameActive: () => { },
  setNewCard: () => { },
  resetDeck: () => { }
});

export const HiloProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [betAmount, setBetAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<Card>({ rank: '', suit: '', color: 'black', id: 'initial-card', side: 'back' });
  const [isSwiping, setIsSwiping] = useState<boolean>(false);
  const [deck, setDeck] = useState<DeckCard[]>([currentCard]);
  const [gameActive, setGameActive] = useState<boolean>(false);
  const animationTimeout = 300;

  const generateCard = useCallback((initial: boolean = false): Card => {
    const ranks: Card['rank'][] = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
    const suits: Card['suit'][] = ['♠', '♡', '♢', '♣']; // TODO: replace to assets

    const rank = ranks[Math.floor(Math.random() * ranks.length)];
    const suit = suits[Math.floor(Math.random() * suits.length)];
    const color = suit === '♠' || suit === '♣' ? 'black' : 'red';
    const id = (initial ? 'initial-card-' : 'hilo-card-') + Math.random().toString(36)
    const side = 'back'

    return { rank, suit, color, id, side };
  }, []);

  const flipCard = useCallback((card: Card): Card => {
    return { ...card, side: 'front' }
  }, [])

  const swipeCard = useCallback(() => {
    setIsSwiping(true)
    setTimeout(() => {
      setIsSwiping(false)
    }, animationTimeout)
  }, [])

  // generate initial card if not existing game
  // only on first render
  useEffect(() => {
    if (gameActive) return
    activeGame()
      .then(game => {
        if (!game.active) throw new Error('No active game')

        const newDeck: DeckCard[] = []
        setGameActive(true)
        const initialCard = {
          rank: game.state.startCard.rank as Card['rank'],
          suit: game.state.startCard.suit as Card['suit'],
          color: ['♠', '♣'].includes(game.state.startCard.suit) ? 'black' : 'red',
          side: 'front',
          id: 'initial-card-' + Math.random().toString(36)
        } as Card
        newDeck.push(initialCard)

        game.state.rounds.forEach((round, index) => {
          const card = {
            rank: round.card.rank as Card['rank'],
            suit: round.card.suit as Card['suit'],
            color: ['♠', '♣'].includes(round.card.suit) ? 'black' : 'red',
            side: 'front',
            payoutMultiplier: round.payoutMultiplier,
            id: 'hilo-card-' + Math.random().toString(36)
          } as DeckCard
          
          // set choice for prev card
          newDeck[index] = { ...newDeck[index], choice: round.guess as DeckCard['choice'] }
          newDeck.push(card)
        })

        setCurrentCard(newDeck[newDeck.length - 1]);
        setDeck(newDeck)
      })
      .catch(() => {
        const card = generateCard(true);
        console.log(card)
        setCurrentCard({ ...card, side: 'front' });
        setDeck([{ ...card, side: 'front' }])
      })
  }, []);

  // set new card
  // use this to make animations & footer work properly
  // the first card has no multiplier
  // the last card has no choice
  const setNewCard = (initial?: boolean, rank?: Card['rank'], suit?: Card['suit'], choice?: DeckCard['choice'], multiplier?: DeckCard['payoutMultiplier']) => {
    if (!initial && !choice && !multiplier) throw new Error('setNewCard: choice & multiplier is required if initial is false')
    
    const card = generateCard(initial);
    if (rank) card.rank = rank
    if (suit) card.suit = suit
    card.color = card.suit === '♠' || card.suit === '♣' ? 'black' : 'red';
    const flippedCard = flipCard(card);
    setCurrentCard(card)

    initial ?
      setDeck([card]) :
      setDeck(prevDeck => [...prevDeck.slice(0, -1), { ...prevDeck.slice(-1)[0], choice: choice }, { ...card, payoutMultiplier: multiplier }]);

    setTimeout(() => {
      setCurrentCard(flippedCard)
      initial ?
        setDeck([{ ...flippedCard }]) :
        setDeck(prevDeck => {
          const newDeck = [...prevDeck.slice(0, -1), { ...flippedCard, payoutMultiplier: multiplier }]
          return newDeck
        });
      
    }, animationTimeout)
  }

  // use this to make animations & footer work properly
  const resetDeck = useCallback(() => {
    setDeck([{ ...currentCard, id: 'initial-card-' + Math.random().toString(36), side: 'back' }])
    setTimeout(() => setDeck(prevDeck => [{ ...prevDeck[0], side: 'front' }]), animationTimeout)
  }, [currentCard])

  return (
    <HiloContext.Provider value={{
      betAmount, setBetAmount,
      isLoading, setIsLoading,
      currentCard, generateCard,
      isSwiping, setIsSwiping, swipeCard,
      deck, resetDeck,
      gameActive, setGameActive,
      setNewCard, setCurrentCard
    }}>
      {children}
    </HiloContext.Provider>
  );
};