import { toast } from "react-toastify";

export const showToast = (type, message, children) => {
  toast(message, {
    position: 'top-center',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    progress: undefined,
    theme: 'colored',
    type,
    ...children
  })
}

export const TOASTTYPE = {
  error: 'error',
  success: 'success',
  info: 'info',
  warning: 'warning'
};

export const LOGGEDIN = 'You\'ve logged in.';
export const LOGINERROR = 'An issue occured trying to log in.';
export const SESSION_EXPIRED = 'Session expired.'
