import { useContext } from "react";
import { Box, Typography, TextField, InputAdornment } from "@mui/material";

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { textFieldStyles } from "../../utils";
import { HiloContext } from "../../../../contexts/HiloContext";
import { getProbabilityAndMultiplier } from "../../../../utils/getHiloProbabiltyMultiplier";
import { colors } from "../../../../utils/colors";

const ProfitField = ({ isTablet }: { isTablet: boolean }) => {
  const { betAmount, deck, currentCard } = useContext(HiloContext);

  const lower = getProbabilityAndMultiplier(currentCard.rank, "lower")
  const higher = getProbabilityAndMultiplier(currentCard.rank, "higher")
  const totalMultiplier = deck.length > 1? deck[deck.length - 1].payoutMultiplier || 0 : 1

  const renderTextField = (
    { label, value, adornmentIcon }: { label: string, value: string, adornmentIcon?: JSX.Element },
  ) => (
    <Box sx={{ width: '100%', textAlign: 'left' }} >
      <Typography sx={{
        color: colors.freshWhite,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: 700
      }}>
        {label}
      </Typography>
      <TextField
        value={value}
        type="number"
        inputProps={{
          readOnly: true,
        }}
        sx={{
          ...textFieldStyles,
          '& .MuiOutlinedInput-root': {
            ...textFieldStyles['& .MuiOutlinedInput-root'],
            '& .MuiInputBase-input': {
              ...textFieldStyles['& .MuiOutlinedInput-root']['& .MuiInputBase-input'],
              cursor: 'default',
            },
          },
        }}
        InputProps={adornmentIcon && {
          style: { cursor: 'default' },
          startAdornment: (
            <InputAdornment position="start" sx={{ color: '#fff' }}>
              {adornmentIcon}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { lg: 'row', xs: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        p: isTablet? '8px 16px': '16px',
        my: isTablet? '4px': '16px',
        width: '100%',
        columnCount: 3,
        backgroundColor: colors.darkGrey,
        borderRadius: '8px',
      }}
    >
      {!isTablet && renderTextField({
        label: `Profit if higher (${higher.multiplier.toFixed(2)}×)`,
        value: (betAmount * totalMultiplier * higher.multiplier).toFixed(6),
        adornmentIcon: <KeyboardDoubleArrowUpIcon />,
      })}
      {!isTablet && renderTextField({
        label: `Profit if lower (${lower.multiplier.toFixed(2)}×)`,
        value: (betAmount * totalMultiplier * lower.multiplier).toFixed(6),
        adornmentIcon: <KeyboardDoubleArrowDownIcon />,
      })}
      {renderTextField({
        label: `Current profit (${(totalMultiplier).toFixed(2)}×)`,
        value: (betAmount * totalMultiplier).toFixed(6),
      })}
    </Box>
  );
}

export default ProfitField;
