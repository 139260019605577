import React, { useContext, useEffect } from "react"
import { Box, Typography, useMediaQuery, useTheme, Button, styled } from '@mui/material';

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import BombCount from "./BombCount";
import MultiplierField from "./MultiplierField";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import BetButton from "../../common/BetButton";

import { MinesContext } from '../../../../contexts/MinesContext';
import { colors } from "../../../../utils/colors";
import httpClient from "../../../../utils/http-client";
import { game, bet, cashout } from "../mockRequest";


const TableButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',
  backgroundColor: colors.grey800,
  '&:hover': {
    backgroundColor: colors.freshGrey
  },
  '&.Mui-disabled': {
    color: colors.grey500,
    opacity: 0.75,
  },
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  color: colors.textWhite,
}))

const gridSizes = [25, 36, 49, 64]

const BetMenu = () => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));
  const { gridSize, setGridSize,
    setSelectedCells,
    setBombCells,
    maxBombsCount,
    currentMultiplier, setCurrentMultiplier,
    isActive, setIsActive,
    isLoading, setIsLoading,
    betAmount, setBetAmount } = useContext(MinesContext);


  useEffect(() => {
    // get active game
    httpClient.post("/games/mines/activeGame", {})
      .then((response) => {
        // setSelectedCells, etc...
      }).catch((error) => {
        // nothing
      })
  }, [])

  const changeGridSize = (size: number) => {
    setSelectedCells([])
    setBombCells([])
    setCurrentMultiplier(-1)
    setGridSize(size)
  }

  const playGame = () => {
    setSelectedCells([])
    setBombCells([])
    setCurrentMultiplier(-1)
    setIsLoading(true);
    httpClient.post("/games/mines/bet", {
      amount: betAmount,
      minesCount: maxBombsCount,
      gridSize: gridSize
    }).then((response) => {

      setIsLoading(false);
    }).catch((error) => {
      // mock
      const response = bet(betAmount, maxBombsCount, gridSize);
      setIsActive(true);
      setCurrentMultiplier(response.state.currentMultiplier);
      setIsLoading(false);
    })
  }

  const cashoutGame = () => {
    httpClient.post("/games/mines/cashout", {}).then((response) => {
    }).catch((error) => {
      // mock
      const response = cashout();
      console.log(response)
      setIsActive(false);
      setBombCells(response?.state.mines || [])
    })
  }

  return (
    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} disabled={isLoading || isActive} />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        // backgroundColor: colors.grey800,
        border: '1px solid ' + colors.borderGrey,
        borderRadius: '8px',
        padding: '8px',
        marginY: '16px',
      }}>
        <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: 600, paddingX: '4px', marginBottom: '4px' }}>Grid Size</Typography>

        <Box sx={{ width: '100%', display: 'grid', gap: '8px', gridTemplateColumns: { xs: 'repeat(2,minmax(0,1fr))', md: 'repeat(4,minmax(0,1fr))' } }}>
          {gridSizes.map(size => (
            <TableButton
              key={"minesGridSize" + String(size)}
              onClick={() => changeGridSize(size)}
              disabled={isLoading || size === gridSize || isActive}
              sx={{ border: size === gridSize ? '1px solid ' + colors.grey500 : 'none' }}
            >
              <Typography variant="body2" sx={{ flexGrow: 1, fontWeight: 700, padding: '3px 6px' }}>{size}</Typography>
            </TableButton>
          ))}
        </Box>
      </Box>

      <BombCount disabled={isLoading || isActive} />
      <BetButton
        disabled={isLoading || currentMultiplier === 0}
        onClick={isActive ? cashoutGame : playGame}
        sx={{
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>
          {isActive ? `Cashout ${(currentMultiplier * betAmount).toFixed(4)} MCOQ` : 'Bet'}
        </Typography>
        {isLoading && <Loader />}
      </BetButton>
      {isActive && <MultiplierField betAmount={betAmount} />}
    </BetMenuWrapper>
  )
}

export default BetMenu