import { keyframes } from "@mui/material";

const swipeDiagonalRight = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(200%, -200%) rotate(20deg);
  }
`;

const swipeDiagonalLeft = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(-200%, -200%) rotate(-20deg);
  }
`;


export const swipeAnimations = [swipeDiagonalRight, swipeDiagonalLeft]