import { useContext } from "react"
import { Box, Slider, Typography } from "@mui/material"
import { DiceContext } from "../../../../contexts/DiceContext"
import { colors } from "../../../../utils/colors"
import { DICE_HOUSE_EDGE } from "../../../../utils/config"

const GameSlider = () => {
  const {
    condition,
    target,
    setTargetAndWinChance,
    pastBets,
    isLoading
  } = useContext(DiceContext)

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    const clampedValue = Math.min(Math.max(value, condition === 'above' ? 100-DICE_HOUSE_EDGE*100 : 2), condition === 'above'? 98 : DICE_HOUSE_EDGE*100);
    setTargetAndWinChance(clampedValue);
  };

  return (
    <Box // slider field
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginY: '48px',
        paddingX: { xs: '16px', lg: '0px' },
      }}
    >
      <Box
        sx={{
          maxWidth: '730px',
          width: '100%',
          maxHeight: '58px',
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          paddingX: '0px'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            position: 'absolute',
            bottom: 'calc(100% + 2px)'
          }}>
          {[0.5, 25, 50, 75, 99.5].map((offset, i) => (
            <Box key={i}
              sx={{
                left: `${offset}%`,
                position: 'absolute',
                textAlign: 'center',
                transform: 'translate(-50%)',
                '::after': {
                  content: '""',
                  bottom: 0,
                  position: 'absolute',
                  transform: 'translateX(-50%)',
                  width: '0px',
                  height: '0px',
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderBottom: '6px solid ' + colors.graphite,
                }
              }}>
              <Typography sx={{
                color: colors.textWhite,
                fontWeight: 600,
                fontSize: '18px',
                position: 'absolute',
                bottom: '-10px',
                transform: 'translate(-50%, -50%)'
              }}>
                {i * 25}
              </Typography>
            </Box>
          ))}
        </Box>
        <Slider
          value={target}
          onChange={handleSliderChange}
          aria-labelledby="chance-slider"
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={100}
          disabled={isLoading}
          sx={{
            height: '8px',
            width: '100%',
            padding: '13px 0px !important',
            '& .MuiSlider-track': {
              background: condition === 'below' ? colors.lightGreen : '#f44336',
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              '&:before': {
                boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, 0.16)',
              },
              zIndex: 3,
            },
            '& .MuiSlider-rail': {
              background: condition === 'below' ? '#f44336' : colors.lightGreen,
              opacity: 1,
              outline: '16px solid ' + colors.graphite,
            },
            '& .MuiSlider-valueLabel': {
              zIndex: 4,
            },
          }}
        />
        <Box // indicator with a dropped number
          sx={{
            display: pastBets.length > 0 ? 'flex' : 'none',
            position: 'absolute',
            top: '-28px',
            left: `calc(${pastBets.length > 0 ? pastBets[0].result : 0}%)`,
            transform: 'translateX(-50%)',
            transition: 'all 0.3s ease-in-out',
            backgroundColor: '#fff',
            color: '#000',
            padding: '4px 8px',
            borderRadius: '4px',
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-8px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: 0,
              height: 0,
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              borderTop: `8px solid #fff`,
            },
          }}
        >
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: pastBets.length > 0 && pastBets[0].won ? colors.lightGreen : '#f44336'
          }}>
            {pastBets.length > 0 ? pastBets[0].result.toFixed(2) : 0}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default GameSlider