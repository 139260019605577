import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../utils/colors";
import { MinesContext } from "../../../../contexts/MinesContext";

const CashoutModal = () => {
  const { currentMultiplier, betAmount } = useContext(MinesContext)
  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 10
    }}>
      <Box sx={{
        width: '162px',
        height: '90px',
        paddingX: '16px',
        paddingY: '12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        backgroundColor: colors.highlightGreen,
        borderRadius: '12px',
      }}>
        <Typography variant="h4" sx={{fontWeight: 600}}>{currentMultiplier.toFixed(2)}x</Typography>
        <Box sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: '4px', marginTop: '8px'}}>
          <Typography sx={{ fontWeight: 600, fontSize: '16px', px: '8px' }}>
            {(betAmount * currentMultiplier).toFixed(2)} MCOQ
            </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CashoutModal