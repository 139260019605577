import { useContext } from 'react';

import { Toolbar } from '@mui/material';
import Box from '@mui/material/Box';

import { PARTNER_ID, SPORTSBOOK_URL } from '../utils/config';
import { UserDetailsContext } from '../contexts/UserContext';


export default function Main() {
  const {
    userJWT,
  } = useContext(UserDetailsContext);

  return (
    <Box
      component="main"
      sx={{ p: 0, height: '100vh', width: '100%', backgroundColor: '#000' }}
    >
      <Toolbar variant="dense" />
      <Box sx={{ height: `calc(100% - ${56}px)` }}>
        <iframe
          title="sportsbook"
          name="embeddediframe"
          src={`${SPORTSBOOK_URL}?user_token=${userJWT}&partner_id=${PARTNER_ID}&lang=en`}
          allowFullScreen
        />
      </Box>
    </Box>
  )
}