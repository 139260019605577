import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Dropdown, Option } from '../components/FilterPopover';

import { BETFILTEROPTIONS } from "../../../utils/config";

const options: Option[] = BETFILTEROPTIONS.map((option) => ({ label: option, data: option.toLocaleLowerCase() }));

type SettlementFilterProps = {
  isLoading: boolean,
}
export const SettlementFilter = ({ isLoading }: SettlementFilterProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  const setSettlementFilter = (optionData: Option['data']) => {
    optionData !== options[0].data ? searchParams.set('settlement', optionData) : searchParams.delete('settlement');
    navigate({ search: searchParams.toString() });
    setAnchorEl(null);
  }

  const getPreviewText = () => {
    const option = options.find((option) => option.data === searchParams.get('settlement'))
    return option?.label || (BETFILTEROPTIONS[0] + ' Statuses');
  }

  return (
    <Dropdown 
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      previewText={getPreviewText()}
      title='Status'
      isLoading={isLoading}
      mainOptions={options}
      selectOptionCallback={setSettlementFilter}
    />
  )
} 

export default SettlementFilter