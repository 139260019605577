import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import { UserDetailsContext } from '../../contexts/UserContext';
import { McoqPriceContext } from '../../contexts/McoqPriceContext';

const styles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  border: 3,
  borderRadius: '7px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#000',
  color: '#FFF',
  fontSize: '16px',
  fontWeight: 700,
  borderColor: 'secondary.main',
  p: 1,
  userSelect: 'none',
}

const BalanceDisplay = () => {
  const { balance, userJWT } = useContext(UserDetailsContext);
  const { price } = useContext(McoqPriceContext);
  const [showDollarValue, setShowDollarValue] = useState(false);

  const handleClick = () => {
    setShowDollarValue(!showDollarValue);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        display: userJWT === '' ? 'none' : 'block',
        minWidth: `${Math.max(balance.length * 13.5, 108)}px`, // 
        width: '100%',
        height: '45px',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.6s',
          transform: showDollarValue ? 'rotateY(180deg)' : 'rotateY(0deg)',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{...styles,
            minWidth: `${Math.max(balance.length * 13.5, 108)}px`,
          }}
        >
          {balance}
        </Box>
        <Box
          sx={{
            ...styles,
            minWidth: `${Math.max(balance.length * 13.5, 108)}px`,
            transform: 'rotateY(180deg)',
          }}
        >
          {balance.includes("??") ? balance : `$${(Number(balance.replace(' MCOQ', '')) * price).toFixed(2)}`}
        </Box>
      </Box>
    </Box>
  );
}

export default BalanceDisplay;
