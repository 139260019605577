import React, { useContext, useState } from "react";
import { Box, TextField, InputAdornment } from '@mui/material';

import InputButton from "./InputButton";
import InputLabel from "./InputLabel";

import { UserDetailsContext } from "../../../contexts/UserContext";
import { colors } from "../../../utils/colors";
import { textFieldStyles } from "../utils";

const InputField = ({ betAmount, setBetAmount, disabled = false, maxBet }:
  { betAmount: number, setBetAmount: React.Dispatch<React.SetStateAction<number>>, disabled?: boolean, maxBet?: number }) => {

  const { balance } = useContext(UserDetailsContext);
  const [inputValue, setInputValue] = useState(betAmount.toString());
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rawValue = event.target.value;

    if (rawValue.includes('.')) {
      const [integerPart, decimalPart] = rawValue.split('.');
      rawValue = integerPart + '.' + decimalPart.slice(0, 2);
    }

    const sanitizedValue = rawValue.replace(/^0+(?=\d)/, '');

    setInputValue(sanitizedValue);
    setBetAmount(Number(sanitizedValue));
  }

  const handleMultiplierButton = (multiplier: number) => {
    const newBetAmount = (betAmount * multiplier).toFixed(2);
    setInputValue(newBetAmount.toString());
    setBetAmount(Number(newBetAmount));
  }

  const handleMaxButton = () => {
    const maxBetAmount = Math.min(parseFloat(balance.includes('?') ? '0' : balance.replace(' MCOQ', '')), (maxBet || 1_000_000)).toFixed(2);
    setInputValue(maxBetAmount);
    setBetAmount(Number(maxBetAmount));
  }

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <Box sx={{ width: '100%', paddingY: '0px' }}>
      <div>
        <InputLabel betAmount={betAmount} />
        <TextField
          disabled={disabled}
          value={isFocused ? inputValue : (betAmount ? betAmount : '')}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          id="outlined-basic"
          variant="outlined"
          label="MCOQ"
          type="number"
          autoComplete="off"
          inputProps={{ 
            min: 0,
            step: 0.01
           }}
          InputLabelProps={{
            style: { color: colors.freshWhite },
          }}
          sx={textFieldStyles}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InputButton disabled={disabled} onClick={() => handleMultiplierButton(0.5)} sx={{ mx: '2px' }}>½ x</InputButton>
                <InputButton disabled={disabled} onClick={() => handleMultiplierButton(2)} sx={{ mx: '2px' }}>2 x</InputButton>
                <InputButton disabled={disabled} onClick={handleMaxButton} sx={{ mx: '2px' }}>Max</InputButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Box>
  );
}

export default InputField;
