import { Box, useMediaQuery, useTheme } from "@mui/material"
import { colors } from "../utils/colors";

const BetMenuWrapper = ({ children }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLG = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={{
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      width: isLG ? '400px' : '100%',
      minHeight: { lg: 'max-content' },
      paddingX: '16px',
      paddingY: isTablet? '6px' : '20px'
    }}>
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        paddingX: '16px',
        backgroundColor: colors.graphite,
        borderRadius: '8px',
      }}>
        <Box sx={{ width: '100%', paddingY: isTablet? '8px' : '24px', ...(isTablet && {pb: '24px'}) }}>
          {children}
        </Box>
      </Box>
    </Box>
  )
}

export default BetMenuWrapper