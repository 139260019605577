import { Popover, styled, Button, Typography, MenuItem } from '@mui/material';

import { colors } from '../../../utils/colors';

export const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#000',
    border: '2px solid ' + colors.pink,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '8px',
  },
  '& .MuiMenuItem-root': {
    color: colors.textWhite,
    margin: '4px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: colors.pink,
    },

  },
}));

export type Option = {
  label: string
  data: any
}
type FilterPopoverProps = {
  anchorEl: any
  setAnchorEl: React.Dispatch<React.SetStateAction<any>>
  title: string
  previewText: string,
  isLoading: boolean,
  mainOptions: Option[]
  selectOptionCallback: (optionData: any) => void
  subOption?: React.ReactNode
}
export const Dropdown = ({ anchorEl, setAnchorEl, title, previewText, isLoading, mainOptions, selectOptionCallback, subOption }: FilterPopoverProps) => {

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ margin: '0px 4px' }}>
      <Button disabled={isLoading} onClick={handleClick} sx={{
        height: '28px',
        borderRadius: '8px',
        color: "#fff",
        backgroundColor: colors.pink,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: 1.75,
        padding: '6px 8px',
        border: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: "max-content",
        textTransform: 'none',
        '&:hover': {
          backgroundColor: colors.transparentPink,
        },
      }}>
        {previewText}
      </Button>
      <StyledPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ color: colors.textWhite, fontSize: '14px', fontWeight: 600, textAlign: 'center' }}>{title}</Typography>
        {mainOptions.map((option, index) => (
          <MenuItem key={`stakefilter-${index}`} onClick={() => selectOptionCallback(option.data)}>
            {option.label}
          </MenuItem>
        ))}
        {subOption}
      </StyledPopover>
    </div>
  )
}