import { Box, Button } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { DiceContext } from "../../../../contexts/DiceContext";
import { colors } from "../../../../utils/colors";
import "./styles.css"

const History = () => {
  const [displayedBets, setDisplayedBets] = useState<any[]>([]);
  const { pastBets } = useContext(DiceContext);

  useEffect(() => {
    setDisplayedBets((prevBets) => {
      const updatedBets = pastBets.reduce((acc, bet) => {
        // for avoid duplication
        const existingIndex = acc.findIndex((b) => b.id === bet.id);
        if (existingIndex >= 0) {
          acc[existingIndex] = bet;
        } else {
          acc.unshift(bet);
        }
        return acc;
      }, [...prevBets]);
      return updatedBets.slice(0, 5);
    });
  }, [pastBets]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Box className={`past-bets ${displayedBets.length >= 5 ? 'full' : ''}`} sx={{
        height: '40px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        {displayedBets.map((pastBet, index) => (
          <div key={`${pastBet.id}${pastBet.result}`} style={{ right: index * 68 }}>
            <Button
              sx={{
                borderRadius: '16px',
                backgroundColor: pastBet.won ? colors.lightGreen : colors.pink,
                color: pastBet.won ? '#000' : '#fff',
                transition: 'filter 0.3s',
                '&:hover': {
                  backgroundColor: pastBet.won ? colors.lightGreen : colors.pink,
                  filter: 'brightness(1.2)',
                },
                width: '60px',
                height: '32px',
                fontWeight: 600,
              }}
            >
              {pastBet.result.toFixed(2)}
            </Button>
          </div>
        ))}
      </Box>

    </Box>
  );
};

export default History