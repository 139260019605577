import React, { useState, createContext, ReactNode } from 'react';

interface MinesContextProps {
  bombCells: number[];
  setBombCells: React.Dispatch<React.SetStateAction<number[]>>;
  selectedCells: number[];
  setSelectedCells: React.Dispatch<React.SetStateAction<number[]>>;
  gridSize: number;
  setGridSize: React.Dispatch<React.SetStateAction<number>>;
  maxBombsCount: number;
  setMaxBombsCount: React.Dispatch<React.SetStateAction<number>>;
  currentMultiplier: number;
  setCurrentMultiplier: React.Dispatch<React.SetStateAction<number>>;
  nextMultiplier: number;
  setNextMultiplier: React.Dispatch<React.SetStateAction<number>>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MinesContext = createContext<MinesContextProps>({
  bombCells: [],
  setBombCells: () => { },
  selectedCells: [],
  setSelectedCells: () => { },
  gridSize: 25,
  setGridSize: () => { },
  maxBombsCount: 3,
  setMaxBombsCount: () => { },
  currentMultiplier: 0,
  setCurrentMultiplier: () => { },
  nextMultiplier: 0,
  setNextMultiplier: () => { },
  isActive: false,
  setIsActive: () => { },
  betAmount: 0,
  setBetAmount: () => { },
  isLoading: false,
  setIsLoading: () => { }
});

export const MinesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [bombCells, setBombCells] = useState<number[]>([]);
  const [selectedCells, setSelectedCells] = useState<number[]>([]);
  const [gridSize, setGridSize] = useState<number>(25);
  const [maxBombsCount, setMaxBombsCount] = useState<number>(3);
  const [currentMultiplier, setCurrentMultiplier] = useState<number>(-1);
  const [nextMultiplier, setNextMultiplier] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);

  const [betAmount, setBetAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <MinesContext.Provider value={{
      bombCells, setBombCells,
      selectedCells, setSelectedCells,
      gridSize, setGridSize,
      maxBombsCount, setMaxBombsCount,
      currentMultiplier, setCurrentMultiplier,
      nextMultiplier, setNextMultiplier,
      isActive, setIsActive,
      betAmount, setBetAmount,
      isLoading, setIsLoading
    }}>
      {children}
    </MinesContext.Provider>
  );
};