import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { connectorsForWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { createConfig, WagmiProvider } from 'wagmi';
import { avalanche } from 'wagmi/chains';
import { http } from 'viem';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  metaMaskWallet,
  trustWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  coreWallet,
} from '@rainbow-me/rainbowkit/wallets';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


const projectId = 'af1a19999c673b76d8e8499ab35e7d55'

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, trustWallet, rainbowWallet, walletConnectWallet, coinbaseWallet, coreWallet],
    },
  ],
  {
    appName: 'coqbook',
    projectId: projectId,
  }
);

const wagmiConfig = createConfig({
  connectors,
  chains: [
    avalanche,
  ],
  transports: {
    [avalanche.id]: http(),
  },
});

root.render(
  <React.StrictMode>
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          theme={darkTheme({
             accentColor: '#ff5252',
             accentColorForeground: 'white',
             borderRadius: 'small',
             fontStack: 'system',
             overlayBlur: 'small',
          })}
        >
          <App />
          <ToastContainer
            toastClassName="toast-content"
          />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
