import React, { useContext } from "react"
import { Typography } from '@mui/material';

import { parseUnits } from "viem";

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import ProfitField from "./ProfitField";
import BetButton from "../../common/BetButton";

import httpClient from "../../../../utils/http-client";
import { showToast } from "../../../../utils/toast";
import { colors } from "../../../../utils/colors";
import { TOASTTYPE } from "../../../../utils/config";
import { DiceContext } from "../../../../contexts/DiceContext";
import { UserDetailsContext } from "../../../../contexts/UserContext";

const BetMenu = () => {
  const {
    betAmount,
    target,
    condition,
    setBetAmount,
    currentMultiplier,
    setPastBets,
    isLoading, setIsLoading,
    maxBet
  } = useContext(DiceContext);

  const { setFormattedBalance } = useContext(UserDetailsContext)

  const playGame = () => {
    setIsLoading(true);
    httpClient.post('dice/bet', {
      target: target,
      amount: String(parseUnits(String(betAmount), 18)),
      condition: condition,
      currency: 'coq'
    }).then((response) => {
      const pastBet = {
        ...response.data,
        won: condition === 'above' ? response.data.result > response.data.target : response.data.result < response.data.target
      }
      setPastBets(prevBets => [pastBet, ...prevBets.slice(0, 4)]);
      setFormattedBalance(response.data.balance);
      setIsLoading(false);
    }).catch((error) => {
      showToast(TOASTTYPE.error, error.response?.data?.message || error.message);
      setIsLoading(false);
    })
  }

  return (
    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} disabled={isLoading} maxBet={maxBet}/>
      <ProfitField profitValue={betAmount * currentMultiplier} />
      <BetButton
        disabled={isLoading}
        onClick={playGame}
        sx={{
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>Bet</Typography>
        {isLoading && <Loader />}
      </BetButton>
    </BetMenuWrapper>
  )
}

export default BetMenu