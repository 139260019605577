import React, { useContext, useEffect } from "react"
import { Typography } from '@mui/material';

import { parseUnits } from "viem";

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import BetButton from "../../common/BetButton";
import GameButtons, { stopActions } from "./GameButtons";

import { showToast } from "../../../../utils/toast";
import { colors } from "../../../../utils/colors";
import { TOASTTYPE } from "../../../../utils/config";
import { BlackjackContext, Card } from "../../../../contexts/BlackjackContext";
import { UserDetailsContext } from "../../../../contexts/UserContext";
import { startGame, activeGame } from "../../../../api/blackjack";

const BetMenu = () => {
  const {
    betAmount, setBetAmount,
    isLoading, setIsLoading,
    gameActive, setGameActive,
    setCardAndFlip, setDealerHand, setPlayerHand,
    setScores, setCurrentHandNumber,
    maxBet, cardSetTimeout,
    addCardsToHand
  } = useContext(BlackjackContext);

  const { setFormattedBalance, userJWT } = useContext(UserDetailsContext)

  const playGame = async () => {
    setDealerHand([]);
    setPlayerHand([]);
    setScores({ player: [0], dealer: [0] });
    setCurrentHandNumber(0);
    setIsLoading(true);
  
    try {
      const game = await startGame(String(parseUnits(String(betAmount), 18)), 'MCOQ');
      setGameActive(game.active);
      setFormattedBalance(game.balance);
  
      const actions = [
        () => setCardAndFlip(game.state.player[0].cards[0] as Card, 'player', 0, true),
        () => setCardAndFlip(game.state.dealer.cards[0] as Card, 'dealer', 0, true),
        () => setCardAndFlip(game.state.player[0].cards[1] as Card, 'player', 0, true),
        game.active ? 
          () => setCardAndFlip(game.state.dealer.cards[0] as Card, 'dealer', 0, false) : 
          () => setCardAndFlip(game.state.dealer.cards[1] as Card, 'dealer', 0, true),
        () => {
          setIsLoading(false);
          setScores({ dealer: [game.state.dealer.value], player: [game.state.player[0].value] });
        },
      ];
      
      actions[0]();
      for (let i = 1; i < actions.length; i++) {
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            actions[i]();
            resolve();
          }, cardSetTimeout);
        });
      }
    } catch (error: any) {
      const errorMessage = typeof error?.response?.data?.message === 'string' ? error.response.data.message : error.message;
      showToast(TOASTTYPE.error, errorMessage);
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    if (!userJWT) return
    setIsLoading(true)
    activeGame()
      .then((game) => {
        game.state.player.forEach((player: any, index: number) => {
          addCardsToHand(player.cards, 'player', index)
        })
        if (
          game.state.player.length > 1 && 
          (game.state.player[0].value >= 21 || game.state.player[0].actions.some(action => stopActions.includes(action)))
        ) {
          setCurrentHandNumber(prev => prev + 1);
        }
        // we can do this because dealer has only one card if game is active
        addCardsToHand([game.state.dealer.cards[0], game.state.dealer.cards[0]], 'dealer', 0)
        setGameActive(game.active)
        setScores({ dealer: [game.state.dealer.value], player: game.state.player.map((player: any) => player.value) })
      })
      .catch((error) => {
        
      })
      .finally(() => {
        setIsLoading(false)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userJWT])

  return (
    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} disabled={isLoading || gameActive} maxBet={maxBet}/>
      <GameButtons/>
      <BetButton
        disabled={isLoading || gameActive}
        onClick={playGame}
        sx={{
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>Bet</Typography>
        {isLoading && <Loader />}
      </BetButton>
    </BetMenuWrapper>
  )
}

export default BetMenu