import { useState } from 'react';
import { Box, TextField, Typography, Button } from '@mui/material';

import Template from './Templates/UserPageTemplate';

import { colors, blackGradient } from '../../utils/colors';
import httpClient from '../../utils/http-client';

export default function Settings() {
  const [inputValue, setInputValue] = useState('');

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onClick = async () => {
    if (inputValue.length > 0) {
      await httpClient.post('/user/update-username', { username: inputValue });
      setInputValue('');
    }
  }

    return (
      <Template>
        <Box sx={{ width: '100%', backgroundColor: colors.blackRussian, backgroundImage: blackGradient, textAlign: 'start', p: '24px', borderRadius: '8px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: colors.graphite, p: '24px', borderRadius: '8px' }}>
            <Typography variant='h6' sx={{ color: colors.textWhite, fontWeight: 700, mb: '16px' }}>Profile settings</Typography>
            <Typography variant='body1' sx={{ color: colors.textWhite, fontWeight: 700, mb: '4px' }}>Change username</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
              <TextField
                variant="outlined"
                size="small"
                value={inputValue}
                onChange={onChange}
                sx={{
                  borderRadius: '8px',
                  width: { xs: '100%', lg: '90%' },
                }}
                placeholder="Current username"
              />
              <Button variant="contained" disabled={inputValue.length < 3 || inputValue.length > 20}
                sx={{
                  borderRadius: '8px', backgroundColor: colors.pink, fontSize: '16px',
                  fontWeight: 700,
                  '&:disabled': {
                    backgroundColor: colors.transparentPink,
                  }
                }}>CHANGE</Button>
            </Box>
          </Box>
        </Box>
      </Template>
    )
  };
