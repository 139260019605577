import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import gem from '../../../../assets/images/ruby_egg.svg';
import { KenoContext } from '../../../../contexts/KenoContext';
import { colors } from '../../../../utils/colors';

const Cell = ({ number, maxSelectedCells }: { number: number, maxSelectedCells: number }) => {
  const { winningCells, selectedCells, setSelectedCells, setWinningCells } = useContext(KenoContext);

  // pink if user selected cell
  let bgColor = selectedCells.includes(number) ? colors.pink : colors.grey800;
  // darker if cell is not winning
  bgColor = winningCells.includes(number) ? colors.darkGrey : bgColor;

  let textColor = selectedCells.includes(number) ? '#000' : colors.textWhite; // selected | not selected
  textColor = !selectedCells.includes(number) && winningCells.includes(number) ? colors.highlightGreen : textColor; // in winning cell
  textColor = selectedCells.includes(number) && winningCells.includes(number) ? 'rgba(2, 222, 130, 0.9)': textColor // in gem

  const borderStyles = {
    borderRadius: '8px',
    border: selectedCells.includes(number) && winningCells.includes(number) ? `1px solid ${colors.pink}` : '1px solid transparent',
    '&:hover': {
      borderColor: colors.grey500,
    },
    boxShadow: selectedCells.includes(number) && winningCells.includes(number) ? `0px 0px 15px -2px ${colors.pink}` : 'none',
  };

  const selectCell = () => {
    if (winningCells) {
      setWinningCells([]);
    }
    if (selectedCells.includes(number)) {
      setSelectedCells(selectedCells.filter(cell => cell !== number));
    } else {
      setSelectedCells([...selectedCells, number]);
    }
  };

  return (
    <Box
      onClick={selectCell}
      sx={{
        ...borderStyles,
        position: 'relative',
        width: '100%',
        aspectRatio: '1 / 1',
        cursor: selectedCells.length < maxSelectedCells || selectedCells.includes(number) ? 'pointer' : 'default',
        pointerEvents: selectedCells.length < maxSelectedCells || selectedCells.includes(number) ? 'auto' : 'none',
        backgroundColor: bgColor,
        transition: 'all 0.15s cubic-bezier(0.4, 0, 0.2, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
      }}>
      <Box
        component={"img"}
        src={gem}
        sx={{
          padding: { xs: '4px', md: '6px' },
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          inset: 0,
          transition: 'opacity 0.3s',
          opacity: winningCells.includes(number) && selectedCells.includes(number) ? 1 : 0,
        }} />
      <Typography sx={{
        position: 'absolute',
        fontSize: { xl: '32px', lg: '28px', md: '24px', sm: '20px', xs: '16px' },
        lineHeight: 1,
        fontWeight: 700,
        textTransform: 'uppercase',
        color: textColor,
        textShadow: winningCells.includes(number) && selectedCells.includes(number)? `
            1px 1px 4px #000,
            1px 1px 4px #000,
            1px 1px 4px #000,
            1px 1px 4px #000
          ` : '',
      }}>
        {number}
      </Typography>
    </Box>
  );
};

export default Cell;